import myRequest from '../http/index.js'
// 富文本
export function aboutAPI(params){
    return myRequest({
        url:"index.php/index/Article/about",         
        params,
        method:"get"
      })
}
//   接口：用户信息
export function infoUserAPI(params){
  return myRequest({
      url:"index.php/index/userinfo/infoUser",         
      params,
      method:"get"
    })
}